import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { AboutComponent } from './pages/about/about.component';
import { ServicesComponent } from './pages/services/services.component';
import { LatestComponent } from './pages/latest/latest.component';
import { ContactComponent } from './pages/contact/contact.component';
import { DespachosComponent } from './pages/despachos/despachos.component';
import { LogisticaComponent } from './pages/logistica/logistica.component';
import { BlogComponent } from './components/blog/blog.component';

const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'about', component: AboutComponent},
  { path: 'services', component: ServicesComponent},
  { path: 'clearance', component: DespachosComponent},
  { path: 'logistic', component: LogisticaComponent},
  { path: 'latest', component: LatestComponent},
  { path: 'contact', component: ContactComponent},
  { path: 'blog/:id', component: BlogComponent},
  { path: '**', redirectTo: 'home'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
