import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';

import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
// components
import { HeaderComponent } from './components/header/header.component';
import { BannerComponent } from './components/banner/banner.component';
import { HomebannerComponent } from './components/homebanner/homebanner.component';
import { HomecontComponent } from './components/homecont/homecont.component';
import { OfferingComponent } from './components/offering/offering.component';
import { HomeslideComponent } from './components/homeslide/homeslide.component';
import { HomemoreComponent } from './components/homemore/homemore.component';
import { HomevideoComponent } from './components/homevideo/homevideo.component';
import { TestimonialsComponent } from './components/testimonials/testimonials.component';
import { BlogComponent } from './components/blog/blog.component';
import { TryservicesComponent } from './components/tryservices/tryservices.component';
import { ListblogComponent } from './components/listblog/listblog.component';
import { FooterComponent } from './components/footer/footer.component';
// pages
import { HomeComponent } from './pages/home/home.component';
import { AboutComponent } from './pages/about/about.component';
import { ServicesComponent } from './pages/services/services.component';
import { LatestComponent } from './pages/latest/latest.component';
import { ContactComponent } from './pages/contact/contact.component';
import { AboutoursComponent } from './components/aboutours/aboutours.component';
import { AboutworksComponent } from './components/aboutworks/aboutworks.component';
import { AboutteamComponent } from './components/aboutteam/aboutteam.component';
import { ServicesourComponent } from './components/servicesour/servicesour.component';
import { DespachosComponent } from './pages/despachos/despachos.component';
import { LogisticaComponent } from './pages/logistica/logistica.component';
import { AvisoComponent } from './components/aviso/aviso.component';



@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomebannerComponent,
    BannerComponent,
    HomecontComponent,
    OfferingComponent,
    HomeslideComponent,
    HomemoreComponent,
    HomevideoComponent,
    TestimonialsComponent,
    BlogComponent,
    TryservicesComponent,
    ListblogComponent,
    FooterComponent,
    HomeComponent,
    AboutComponent,
    ServicesComponent,
    LatestComponent,
    ContactComponent,
    AboutoursComponent,
    AboutworksComponent,
    AboutteamComponent,
    ServicesourComponent,
    DespachosComponent,
    LogisticaComponent,
    AvisoComponent  
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [ HttpClient ]
      }
    })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
