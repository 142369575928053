import { Injectable } from '@angular/core';

export interface NewsInt {
  img?: string;
  titulo?: string;
  fecha?: string;
  texto?: string[];
  fuente?: string;
  url?: string;
}

@Injectable({
  providedIn: 'root'
})
export class NewsService {

  news: NewsInt[];

  constructor() { 
    this.news = [
      {
        img: 'assets/images/news/Octubre15.jpg', 
        titulo: 'Peso mexicano recupera terreno ante incertidumbre de elecciones de EU', 
        fecha: '07 Nov 2018',
        texto: [
          'El tipo de cambio se ubica en 19.72, lo que representa una variación de 0.80% respecto a su cierre previo de 19.88.', 
          'El peso mexicano (MXN) mantiene las ganancias y cierra la jornada por debajo de 20 unidades por dólar (USD). El tipo de cambio se ubica en 19.72, lo que representa una variación de 0.80% respecto a su cierre previo de 19.88.',
          'La atención se centra en las elecciones intermedias en Estados Unidos, que mantienen la incertidumbre ante la posibilidad de que el Partido Demócrata obtenga la mayoría en la Cámara. A lo largo de la jornada, el índice del dólar, que mide la fortaleza de la moneda respecto a una canasta de seis divisas, mostró una ligera debilidad que se disipó a medida que se acercaban las 17:00 horas.',
          'En ventanilla bancaria, el dólar se encontró en precios de entre 19.55 y 20.18.',
          'El eurodólar cotizó en 1.1418, lo que representa una caída de 0.09% para la moneda estadounidense; mientras que en su paridad con el yen, el dólar cotiza en 113.46 unidades, con una variación 0.24%.',
          'Así, los movimientos en general muestran que el dólar podría recuperarse una vez superada la incertidumbre.'
        ],
        fuente: 'El economista', 
        url: 'https://www.eleconomista.com.mx/mercados/Peso-mexicano-recupera-terreno-ante-incertidumbre-de-elecciones-de-EU--20181106-0085.html'
      },
      {
        img: 'assets/images/news/Nov2.jpg',
        titulo: 'Europa lanza con éxito satélite meteorológico MetOp-C',
        fecha: '07 Nov 2018',
        texto: [
          'La Agencia Espacial Europea (ESA) lanzó hoy con éxito desde Kurú (Guayana Francesa) el MetOp-C, el tercer y último dispositivo de una nueva generación de satélites meteorológicos que persigue mejorar de forma sustancial la calidad de los pronósticos.',
          'Como estaba previsto, el MetOp-C despegó a las 00:47 GMT del Puerto Espacial Europeo con un cohete Soyuz-2, en una operación sin incidentes dirigida desde la sede de la ESA en Darmstadt (Alemania).',
          'En un primer momento se planeó que cada miembro de la familia MetOp tuviese una vida útil de unos cinco años y que fuese sustituido por el siguiente en ser lanzado, pero los dos primeros satélites siguen funcionando sin problemas mucho más allá de las previsiones, por lo que se van a mantener los tres operativos de forma paralela.',
          '"Lanzar satélites de forma secuencial asegura observaciones continuas de una serie de variables atmosféricas como temperatura, humedad, gases traza, ozono y velocidad del viento sobre el océano", datos que se emplean para "predicciones numéricas", explicó previamente la ESA en un comunicado.',
          'Según los últimos estudios, los datos aportados por los dos primeros miembros de la familia MetOp han mejorado sensiblemente las previsiones meteorológicas para el día siguiente al reducir los errores en un 27%.',
          'Los tres satélites operarán en la misma órbita polar (pasando por los polos), a 837 kilómetros de la Tierra, y estarán separados unos de otros por la misma distancia, a 120 grados, para mejorar la calidad de los pronósticos meteorológicos.',
          'Los MetOp están mucho más cerca de la Tierra que otros satélites meteorológicos europeos, como los Meteosat, que se encuentran en una órbita geoestacionaria (fijos sobre un punto del planeta al girar a la misma velocidad de rotación de la Tierra), a 36 mil kilómetros de distancia.',
          'El MetOp-C, como sus dos hermanos, ha sido desarrollado por la ESA bajo un acuerdo de cooperación con la Organización Europea para la Explotación de Satélites Meteorológicos (EUMETSAT), que se hará cargo del satélite en cuanto sea puesto en órbita.',
          'La familia MetOp es además la "contribución europea" al sistema de múltiples satélites meteorológicos que comparten la Unión Europea y Estados Unidos.'
        ],
        fuente: 'Milenio',
        url: 'https://www.milenio.com/ciencia-y-salud/europa-lanza-exito-satelite-meteorologico-metop-c'
      },
      {
        img: 'assets/images/news/Nov1.jpeg',
        titulo: 'Amazon elimina cantidad mínima de compra para envíos gratis',
        fecha: '06 Nov 2018',
        texto: [
          'Amazon ha decidido seguir los pasos de Target y eliminó temporalmente el monto mínimo de compra para obtener el envío gratis.',
          'Generalmente, los clientes de Amazon necesitan comprar al menos 25 dólares en mercancía para que el envío sea gratis, o pagar 119 dólares por una membresía Prime. La oferta de Amazon, que comenzó este lunes, aplica sobre cientos de millones de artículos y en pedidos que lleguen a tiempo para Navidad.',
          'Aun así, los compradores que no cuenten con la membresía Prime tardarán más en recibir sus artículos, entre cinco y ocho días.',
          'Los comercios compiten duro por las ventas de fin de año, en las que los compradores esperan cada vez más un envío rápido y gratuito.',
          'Target eliminó el monto mínimo de compra la semana pasada y ofreció un envío de dos días sobre cientos de miles de artículos hasta el 22 de diciembre. En tanto, Walmart, que ofrece un envío gratis en dos días en compras de más de 35 dólares, comentó a los reporteros de AP, luego del anuncio de Target, que no tiene planes de cambiar su política de envíos.',
          'Este mismo lunes, Amazon dijo que expandió el número de artículos y ubicaciones donde los miembros Prime pueden recibir sus compras el mismo día sin costo extra.'
        ],
        fuente: 'El Financiero',
        url: 'https://www.elfinanciero.com.mx/tech/amazon-elimina-cantidad-minima-de-compra-para-envios-gratis'
      },
      {
        img: 'assets/images/news/Nov.jpg',
        titulo: 'PepsiCo anuncia nueva meta sobre reciclaje',
        fecha: '06 Nov 2018',
        texto: [
          'Como parte de su visión sobre los plásticos sostenibles, PepsiCo dio a conocer su nuevo objetivo de esforzarse por utilizar 25% de contenido reciclado en sus empaques de plástico para el 2025.',
          'PepsiCo aspira a lograr este objetivo al colaborar con proveedores y socios, para ayudar a aumentar la educación del consumidor, fomentar asociaciones entre los sectores industriales y entre los sectores público y privado, así como también promover una mejor infraestructura de reciclaje y una reforma normativa. El objetivo incluye un propósito específico sobre las botellas de bebidas fabricadas con PET: lograr 33% de contenido de PET reciclado para el 2025.',
          '“Tenemos la intención de lograr esa visión reduciendo, reciclando, reutilizando y reinventando nuestros envases de plástico, y liderando el cambio global a través de asociaciones”, comentó el Dr. Mehmood Khan, vicepresidente y director científico de PepsiCo. “PepsiCo es uno de los mayores usuarios de PET reciclado de tipo alimenticio del mundo. Para impulsar aún más el contenido reciclado en todos los empaques e impulsar el progreso hacia una economía circular, es vital aumentar las tasas mundiales de recolección y reciclaje de desechos mediante la inversión en infraestructura y tecnología de reciclaje”.',
          'La nueva meta de la empresa PepsiCo se basa en las metas anunciadas en el 2016, incluidas en la agenda de la compañía Desempeño con Sentido 2025. La Agenda 2025 incluyó objetivos para que PepsiCo se esfuerce por diseñar 100% de sus envases para que sean reciclables, biodegradables o que se puedan transformar en abono, incrementar el uso de materiales reciclados, reducir el impacto del carbono de sus envases y trabajar para aumentar las tasas de reciclaje.',
          'PepsiCo ha realizado varios anuncios recientes en apoyo de su visión sobre los plásticos sostenibles y los objetivos relacionados de su agenda Desempeño con Sentido 2025. Éstos incluyen:',
          '• A principios de octubre del 2018, PepsiCo firmó un contrato de suministro plurianual con Loop Industries para incorporar plástico Loop PET, que es un material 100% reciclado, en su empaque de productos para mediados del 2020.',
          '• En septiembre del 2018, PepsiCo anunció que colaboraría en la Asociación de Acción Plástica Global, liderada por el Foro Económico Mundial, que trabajará con las partes interesadas en las economías costeras, incluso en el sudeste asiático, para demostrar cómo empresas, comunidades y gobiernos pueden rediseñar sus sistemas de tratamiento de desechos.',
          '• En julio del 2018, Fundación PepsiCo y la organización sin fines de lucro The Recycling Partnership anunciaron el lanzamiento de “All in on Recycling” (“Todos por el reciclaje”).'
        ],
        fuente: 'El Economista',
        url: 'https://www.eleconomista.com.mx/empresas/PepsiCo-anuncia-nueva-meta-sobre-reciclaje-20181105-0118.html'
      }
      // ,
      // {
      //   img: 'assets/images/news/',
      //   titulo: '',
      //   fecha: '',
      //   texto: [],
      //   fuente: '',
      //   url: ''
      // }
    ];
  }
}
