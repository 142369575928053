import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-homemore',
  templateUrl: './homemore.component.html',
  styleUrls: ['./homemore.component.css']
})
export class HomemoreComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
