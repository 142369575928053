import { Component, OnInit } from '@angular/core';
import { MailService } from '../../services/mail.service';
import { NgForm } from '@angular/forms';

class Mail {
  constructor(
    public correo?: string,
    public empresa?: string,
    public telefono?: string,
    public nombre?: string,
    public tema?: string,
    public mesaje?: string
  ) {}
}

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  mail: Mail = new Mail();
  sent: boolean;

  constructor(
    private mailServ: MailService
  ) { }

  ngOnInit() {
    this.sent = false;
  }

  sendMail(f: NgForm) {
    if ( f.invalid ) {
      return;
    }
    // cARGO los valores a enviar 
    let postVars = {
      email : this.mail.correo,
      name : this.mail.nombre,
      company: this.mail.empresa,
      phone: this.mail.telefono,
      tema: this.mail.tema,
      message : this.mail.mesaje
    };
    // console.log(f);
    this.mailServ.sendMail(postVars).subscribe((data: any) => {
      console.log(data);
      this.sent = true;
      this.mail.correo = '';
      this.mail.nombre = '';
      this.mail.empresa = '';
      this.mail.tema = '';
      this.mail.mesaje = '';
      const aceptado = setTimeout(() => {
        this.sent = false;
      }, 2000);
    });
  }

}
