import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MailService {

  constructor(
    private http: HttpClient
  ) { }


  sendMail(vars: any) {
    // console.log(vars);
    let url = 'http://praktors.com/contact.php';
    return this.http.post(url, vars).pipe(map((data: any) => {
      return data;
    }));
  }
  

}
