import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aboutours',
  templateUrl: './aboutours.component.html',
  styleUrls: ['./aboutours.component.css']
})
export class AboutoursComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
