import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aboutworks',
  templateUrl: './aboutworks.component.html',
  styleUrls: ['./aboutworks.component.css']
})
export class AboutworksComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
