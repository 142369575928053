import { Component, OnInit } from '@angular/core';
import { NewsService, NewsInt } from '../../services/news.service';

@Component({
  selector: 'app-latest',
  templateUrl: './latest.component.html',
  styleUrls: ['./latest.component.css']
})
export class LatestComponent implements OnInit {

  news: NewsInt[];

  constructor(
    private newsServ: NewsService
  ) { 
    this.news = this.newsServ.news;
  }

  ngOnInit() {
  }

}
