import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NewsInt, NewsService } from '../../services/news.service';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css']
})
export class BlogComponent implements OnInit {

  id: number;
  new: NewsInt;

  constructor(
    private route: ActivatedRoute,
    private newsServ: NewsService
  ) { 
    this.id = this.route.snapshot.params['id'];
  }

  ngOnInit() {
    this.new = this.newsServ.news[this.id];
    console.log(this.new);
    
  }

}
